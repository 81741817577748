import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from "react-scroll";

function App() {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      console.log(form.current)

      emailjs
      .sendForm('service_5rfbel6', 'template_qhyzz1j', form.current, {
        publicKey: 'UCHyg01qIujRnxoby',
      })
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };

  return (
    <div className="main mx-5">
      <nav className="navbar mx-6 mt-2" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="#home">
            <span className="is-size-5">Kevin</span>
          </a>
        </div>

        <input type="checkbox" id="navbar-burger-toggle" className="navbar-burger-toggle is-hidden"/>
        <label htmlFor="navbar-burger-toggle" className="navbar-burger">
          <span></span>
          <span></span>
          <span></span>
        </label>

        <div className="navbar-menu">
          {/* <div className="navbar-start">
            
          </div> */}

          <div className="navbar-end">
            
            <Link
              className="navbar-item"
              activeClass="active"
              to="section2"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
            >
              <button className="button is-rounded is-dark">
                Lets talk
                <span className="icon ml-1">
                  <svg fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"></path>
                  </svg>
                </span>
              </button>
            </Link>

            <Link
              className="navbar-item"
              activeClass="active"
              to="section1"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
            >
              Selected works
            </Link>

          </div>
        </div>
      </nav>

      <section className="section section-1">
        <h1 className="is-size-1 has-text-weight-bold mt-6 greeting">Hi, i'm Kevin</h1>
        <h1 className="is-size-1">Undergraduate student in Electrical Engineering major at Surabaya State University</h1>
        
        <Link
          className="mt-5 button is-rounded is-medium is-dark"
          activeClass="active"
          to="section2"
          spy={true}
          smooth={true}
          offset={-10}
          duration={500}
        >
          Lets talk
          <span className="icon ml-1">
            <svg fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"></path>
            </svg>
          </span>
        </Link>
      </section>

      <section className="section" id="section1">
        <h1 className="title">Selected works</h1>

        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt="" />
                </figure>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image is-4by2">
                  <img src="https://cdn.dribbble.com/userupload/7020593/file/original-f955f19b99945eec3065edfa7c9c4198.png?resize=400x300&vertical=center" alt=""/>
                </figure>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="section" id="section2">
        <h1 className="title">Lets talk</h1>
        
        <div className="columns is-centered">
          <form className="column is-two-fifths" ref={form} onSubmit={sendEmail}>
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input className="input" type="text" name="user_name" placeholder="Your name..." required/>
              </div>
            </div>

            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input className="input" type="email" name="user_email" placeholder="Email..." required/>
              </div>
            </div>


            <div className="field">
              <label className="label">Message</label>
              <div className="control">
                <textarea className="textarea" placeholder="Message" name="message" required></textarea>
              </div>
            </div>

            <div className="field">
              <div className="control">
                <button className="button is-rounded is-dark px-5 mt-1" type="submit">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <footer className="footer pb-6">
        <div className="content has-text-centered">
          <p>
            Made with <strong>love</strong> by <a target="_blank" rel="noreferrer" href="https://github.com/kevindwi">Kevin</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
